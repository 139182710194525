.Modal {
    z-index: 9999;
    position: absolute;
    top: 5em;
    left: 20em;
    right: 5em;
    bottom: 5em;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    padding: .5em 1em;

    &.products-modal {
        left: 1em;
        right: 1em;
    }

    .modal-header {
        border: none;
        padding: 0;
        align-items: center;

        .modal-title {
            font-weight: 500;
            font-size: 1.2em;
        }

        .modal-close {
            margin: 0;
        }
    }

    .modal-body {
        padding: 1rem 0;
    }
}

.Overlay {
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.75);
}
