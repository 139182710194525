@import "../../variables";

.measures-form {
    .measures-form-header {
        display: flex;

        button {
            border: none;
            box-shadow: $boxShadow;
        }

        .show-measure {
            margin-left: 1em;
        }
    }

    .measures-table {
        display: block;
        width: 100%;
        overflow-x: auto;
        white-space: nowrap;
        box-shadow: $boxShadow;

        th, td {
            border: 1px solid #cdcdcd;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }

        thead {
            background-color: $primaryColor;

            th {
                font-weight: 500;
                padding: .2em .5em;
            }
        }

        tbody {
            td {
                input {
                    border: none;
                    margin: 0;
                    width: 100%;
                }
            }

            .btn-circle {
                width: 1.8em;
                height: 1.8em;

                i {
                    font-size: .7em;
                }

            }
        }

        tfoot {
            td {
                background-color: #dddddd;
                padding: .2em .5em;
            }
        }
    }
}
