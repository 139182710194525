@import "../variables";

.block {
    .block-title {
        .block-add {
            margin: 0 0 0 .5em;
        }
    }

    .block-button {
        padding: .2em 2em;
        border: 1px solid #f0f0f0;
        background-color: $primaryColor;

        &:hover {
            background-color: #e19e4e;
            color: #FFFFFF;
        }
    }
}
