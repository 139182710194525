@import "../../../css/variables";

.react-autosuggest__container {
    position: relative;
    margin: $marginInput;
}

.react-autosuggest__container:first-child {
    margin-left: 0;
}

.react-autosuggest__input {
    width: 100%;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 2.1em;
    width: 100%;
    border: 1px solid #aaa;
    border-top: none;
    background-color: #fff;
    font-weight: 300;
    z-index: 99;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: $inputPadding;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}
