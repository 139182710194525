.cgv {
  .pageBreakBefore {
    page-break-before: always
  }

  .cgv-title {
    text-align: center;
    font-style: italic;
    font-size: 1.5em;
  }
}

