@import "../../variables";

.city {
    margin-bottom: 1em;
}
.city,  .amountAtZero {
    input, select {
        margin-left: 1em;
    }
}

.write {
    width: 100%;
    border-spacing: 0;
    /*border-collapse: separate;
    border-spacing: 0 1em;*/

    tr td {
        border: 1px solid grey;
    }

    thead {
        background-color: $primaryColor;
        font-weight: 500;

        tr td {
            padding: .2em .5em;
        }

    }

    input {
        width: 100%;
    }

    .ck-content p {
        margin-bottom: 0!important;
        line-height: 1em;
    }

    input[name="quantity"], input[name="unitPrice"], input[name="sellingPrice"] {
        text-align: right;
    }

    .react-autosuggest__container {
        margin: 0;
    }

    .actions {
        width: 3.2em;

        .btn-circle {
            width: 2em;
            height: 2em;

            i {
                font-size: .7em;
            }
        }
    }

    .code {
        position: relative;
        width: 10em;

        &:hover .btn-drag {
            opacity: 1;
        }

        .btn-drag {
            position: absolute;
            left: -2em;
            width: 1.5em;
            height: 1.5em;
            opacity: 0;
            -webkit-transition: opacity .5s;
            -moz-transition: opacity .5s;
            -o-transition: opacity .5s;
            transition: opacity .5s;

            i {
                font-size: .65em;
            }

            &.drag-up {
                top: .5em;
            }

            &.drag-down {
                bottom: .5em;
            }
        }

        /*.btn-products {
            position: absolute;
            bottom: 0;
            left: 0;
        }*/

        .btn-circle, .react-autosuggest__container {
            display: inline-block;
        }

        .react-autosuggest__container {
            width: 6.5em;
            float: right;
        }

        .btn-circle {
            margin: 0 .4em;
        }
    }

    .little-cell {
        width: 7em;
    }
}

.btn-header-right {
     button {
        margin-left: 1em;
    }
}

.submit {
    margin-top: 1em;
}
