@import "../variables";

.sr-menu {
    position: relative;
    margin: 0;
    display: none;

    .sr-menu-link {
        cursor: pointer;
        color: #dbdbdb;
        position: relative;
        display: inline-block;
        margin: 0 2em;

        &>a {
            color: #cdcdcd;
        }

        &.active {
            color: $primaryColor;
        }

        &:hover {
            color: white;
        }

        &:hover span, &:hover i {
            color: #ffffff;
        }
    }

    .sr-menu-submenu:hover .sr-menu-submenu-content {
        display: block;
    }

    .sr-menu-submenu-content  {
        z-index: 2;
        display: none;
        position: absolute;
        left: -15px;
        background-color: $secondaryColor;
        width: 13em;
        padding-top: 1em;

        &.active {
            display: block;
        }

        .sr-menu-link {
            display: block;
            margin: 0;
            padding: .5em 1em;
        }
    }

    @media (min-width:1440px) {
        display: block;
    }
}

.sr-menu-mobile {
    display: none;

    .sr-menu-submenu-content {
        display: none;

        &.active {
            display: block;
        }
    }

    @media (max-width:1439px) {
        display: block;
    }
}
