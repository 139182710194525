@import "../variables";

.ReactTable {
    .rt-thead.-filters .rt-tr, .rt-thead.-header .rt-tr {
        padding-right: 1em;
    }

    .rt-thead.-header {
        background-color: $primaryColor;
    }

    .rt-thead.-filters .rt-tr {
        background-color: #f0f0f0;
    }

    .rt-thead .rt-th, .rt-thead .rt-td {
        padding: 3px;
    }

    .rt-thead .rt-th {
        overflow: visible;

        .react-autosuggest__container {
            margin: 0;
        }

        .react-autosuggest__suggestions-container--open {
            text-align: left;
            width: auto!important;
        }

        button {
            border-color: $primaryColor;
            background: $primaryColor;
            color: #000;
        }
    }

    .rt-tbody {
        max-height: 60vh;
        overflow-y: scroll;

        input[type="text"] {
            height: 1.7em;
        }

        .rt-tr-group, .rt-td {
            border-color: rgba(0,0,0,0.1);
        }

        .rt-tr {
            border: 2px solid transparent;
        }

        .rt-td {
            padding: 4px 5px;
            white-space: normal;
        }

        .whiteSpaceNoWrap {
            white-space: nowrap;
        }

        a {
            color: inherit;
        }

        .fa-tint {
            color: #000;
        }
    }

    .text {
        max-width: 100%;
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
    }

    .btn-circle {
        width: 1.5em;
        height: 1.5em;

        i {
            font-size: .6em;
        }
    }

    .sr-free-input {
        width: 100%;
    }
}
