@import "../../variables";

.messages-view {
    i {
        font-weight: bolder;
        color: $primaryColor;
    }

    .flex {
        width: 100%;
        display: flex;

        .property {
            margin-left: 3em;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .box-body {
        .title {
            margin-top: 2em;
        }

        .answer {
            margin-top: 2em;
        }
    }
}
