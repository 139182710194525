@import "../variables";

.activityMonitoring {
    table {
        margin-top: 2em;
        width: 100%;

        th {
            background-color: $primaryColor;
        }

        tfoot {
            font-weight: bold;
        }

        th, td {
            border: 1px solid #000000;
            padding: .2em .5em;
        }

        tr td {
            text-align: right;

            &:first-child {
                text-align: left;
            }
        }
    }

    @media print {
        @page {
            size: auto;
        }
    }
}
