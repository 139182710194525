@import "variables";

input, textarea {
    padding: $inputPadding;
}

form {
    .btn-div, button {
        padding: .4em 2em;
        margin-right: 1em;
        color: #1d1d1d;
        background-color: $primaryColor;
        display: inline-block;
        cursor: pointer;
        border: none;
        border-radius: 5px;

        &:hover {
            background-color: $primaryColorHover;
            color: #1d1d1d;
        }
    }

    .sr-btn-disabled {
        cursor: default;
        background-color: #bebebe;

        &:hover {
            color: #000000;
            background-color: #a8a8a8;
        }
    }
}

.form-block {
    margin-bottom: 3em;

    .form-block-title {
        font-size: 1.25em;
    }

    .row div {
        margin-bottom: 1em;
        div {
            margin: 0;
        }
    }

    label {
        font-weight: 500;
    }

    input, select, textarea {
        width: 100%;
    }

    input[type="checkbox"] {
        width: auto;
    }

    .react-autosuggest__container {
        margin: 0;
    }
}

.form-online {
    input {
        margin: $marginInput;
    }

    input:first-child {
        margin-left: 0;
    }
}

.form-margin {
    margin-bottom: 3em!important;
}
