@import "../../variables";

.box.project {
    .box-header {
        .btn-header-right {
            display: flex;

            button, a {
                margin-left: .5em;

                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }

    .box-body {
        .block {
            margin-top: 2em;

            &:first-child {
                margin-top: 0;
            }

            .block-title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: .5em;

                span {
                    font-weight: bold;
                }

                .btn-hide {
                    margin: 0 0 0 1.5em;
                }
            }
        }
    }
}

.ReactTable {
    box-shadow: $boxShadow;
}
