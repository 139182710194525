@import "../variables";

.sr-messages-alert {
    position: relative;
    background-color: rgba(248, 12, 0, 0.7);
    color: #ffffff;
    margin: 5em 1em 0 1em;
    padding: 1em;
    box-shadow: $boxShadow;

    a, a:hover {
        color: #ffffff;
    }

    .close {
        position: absolute;
        top: 1em;
        right: 1em;
        color: #ffffff;
        cursor: pointer;
    }
}
