@import "../../variables";

.orders-form {
    .articles-list {
        margin-top: 1em;
        width: 100%;
        box-shadow: $boxShadow;

        th, td {
            padding: .2em .5em;
            border: 1px solid #cdcdcd;

            &:first-child {
                border-left: none;
            }

            &:last-child {
                border-right: none;
            }
        }

        th {
            background-color: $primaryColor;
            text-align: center;
            border-top: none;
        }

        td {
            vertical-align: top;

            &:last-child {
                vertical-align: middle;
            }
        }

        tbody tr:last-child td {
            border-bottom: none;
        }

        .cell-input {
            padding: 0;

            input, textarea {
                border: none;
                padding: .3em .5em;
            }

            &.text-right {
                input {
                    text-align: right;
                }
            }
        }

        tbody td:first-child {
            width: 8em;
        }

        .stock-cell {
            width: 13em;
        }

        .medium-cell {
            width: 9em;
        }

        .format-cell {
            width: 10.5em;
        }

        .total-cell {
            width: 6em;
        }

        .small-cell {
            width: 5em;
        }

        tbody td:last-child {
            width: 4em;
        }

        .customer-name {
            padding: 0 .5em .3em .5em;
        }

        .btn-circle {
            width: 1.7em;
            height: 1.7em;

            i {
                font-size: .7em;
            }
        }

        .ref-cell {
            width: 10em!important;

            .ref-container {
                display: flex;
                align-items: center;
            }

            input {
                width: 8em;
            }
        }

        .btn-circle {
            margin: 0 5px;
        }
    }
}
