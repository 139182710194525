@import "../variables";

.notfound {
    margin-top: 25vh;
    text-align: center;

    a {
        font-weight: bold;
    }

    .back {
        cursor: pointer;
        font-weight: bold;
        color: $primaryColor;
    }
}
