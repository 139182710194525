@import "../../variables";

$border: 1px solid #cccccc;

.calendar {
    .overlay-loading {
        z-index: 98;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, .6);
    }

    .sr-loading {
        z-index: 99;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .top {
        background-color: $secondaryColor;
        box-shadow: $boxShadow;
        padding: .5em 1em;

        .btn-flex {
            justify-content: flex-start;

            .btn-circle {
                background-color: $primaryColor;
            }
        }
    }

    .content {
        padding: 1em;
    }

    .showUser {
        margin-bottom: 1em;
    }

    .addStep {
        input, select {
            width: 100%;
        }

        select[name="users"] {
            height: 2.2em;
        }

        select[name="users"]:focus {
            height: auto;
        }

        button {
            background-color: $primaryColor;
        }
    }

    table {
        thead {
            text-align: center;

            th {
                font-size: .9em;
                padding: .5em;
            }

            .today {
                background-color: $primaryColor;
            }
        }

        .nameOfDays {
            text-transform: capitalize;
        }

        tbody tr {
            border: none;
        }

        .step {
            //display: block;
            position: relative;
            margin: .2em;
            padding: .2em;
            border: $border;
            width: calc(100% / 5);
            font-size: .85em;

            &:hover .btn-arrow {
                opacity: 1;
            }

            &:hover {
                padding-left: 2em;
            }

            .btn-arrow {
                position: absolute;
                opacity: 0;
                -webkit-transition: opacity 1s;
                -moz-transition: opacity 1s;
                -o-transition: opacity 1s;
                transition: opacity 1s;
            }

            .btn-left {
                top: 50%;
                left: 0;
                transform: translate(0, -50%);
            }

            .btn-right {
                top: 50%;
                right: 0;
                transform: translate(0, -50%);
            }

            .btn-up {
                top: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }

            .btn-down {
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 0);
            }

            .topStep {
                display: flex;
                justify-content: space-between;

                .btn-circle {
                    height: 1.5em;
                    width: 1.5em;
                    margin-left: .2em;

                    i {
                        font-size: .55em;
                    }
                }
            }

            .user {
                font-size: .85em;
            }

            .priority {
                width: 1em;
                height: 1em;
                color: #000000;
                text-align: center;
                line-height: .8em;
                font-size: .85em;
            }

            .project {
                font-weight: 500;
                font-size: .9em;

                a {
                    color: #000000;
                }
            }
        }

        .users-hours {
            background-color: #ffffff;
            border: $border;
        }
    }
}
