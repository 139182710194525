@import "../variables";

.topbar {
    display: flex;
    justify-content: space-between;
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $secondaryColor;
    color: #bdc5cd;
    box-shadow: $boxShadow;
    padding: 1em;
    vertical-align: middle;
    line-height: 1.5em;

    .close-menu {
        position: relative;
        width: 2.5em;
        height: 2em;
        background-color: $primaryColor;
        color: #fff;
    }

    a, a:hover {
        color: #FFFFFF;
    }

    .right-topbar {
        display: flex;
        align-items: baseline;

        .right-elements {
            position: relative;
            font-size: 1.5em;
            margin-left: 1em;
            color: #FFFFFF;
            cursor: pointer;
        }

        .right-elements:last-child {
            font-size: 1em;
        }

        .messages-active {
            color: $primaryColor;
        }

        .messages-notView {
            position: absolute;
            bottom: -.7em;
            right: -.7em;
            font-size: .65em;
            color: #ffffff;
        }
    }

    @media print {
        display: none;
    }

    @media (min-width:481px) {
        /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */

        .right-topbar {
            .right-elements {
                margin-left: 2em;
            }
        }
    }

    @media screen and (min-width: 771px) {
        .close-menu {
            display: none;
        }
    }
}
