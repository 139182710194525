@import "../../variables";

.customer {
    table {
        border: none;
        box-shadow: $boxShadow;
    }

    .customer-note {
        margin-bottom: 3em;

        h3 {
            font-size: 1.1em;
        }

        textarea {
            padding: .2em .5em;
            width: 100%;
            box-shadow: $boxShadow;
            min-height: 2.5em;
        }
    }
}


