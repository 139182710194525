@import "../../variables";

.contactForm{
    .box-header {
        background-color: $primaryColor;
        box-shadow: $boxShadow;
        padding: .5em 1em;
        text-align: center;
        font-weight: bold;
    }

    input {
        width: calc(100% / 6);
    }

    button {
        width: 10em;
        margin-left: 50%;
        margin-top: 1em;
        transform: translateX(-50%);
    }
}
