@import "../variables";

.sr-table {
    width: 100%;

    th {
        background-color: $primaryColor;
    }

    tfoot {
        font-weight: bold;
    }

    th, td {
        border: 1px solid #828282;
        padding: .2em .5em;
    }

    a {
        color: #000;
    }
}
