.circle-picker {
    background-color: rgba(0, 0, 0, .7);
    border-radius: 1em;
    padding: .5em .5em;
    width: auto!important;

    &>span>div {
        margin: .2em .3em!important;
    }
}
