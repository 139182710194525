.bills-print {
    .customers-name {
        text-overflow: ellipsis;
    }

    .amounts {
        text-align: right;
    }

    @media print {
        font-size: 1.15em;

        @page {
            size: landscape A4;
            max-height: 100%;
            max-width: 100%;
        }
    }
}
