@import "../../variables";

.select-products-groups {
    width: auto!important;
    margin-bottom: 1em;
}

.group-products-container {
    box-shadow: $boxShadow;
}

.group-products-header {
    margin-top: 1.5em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $primaryColor;
    padding: .25em 1em;

    .group-products-title {
        font-weight: 500;
    }

    .btn-right {
        display: flex;

        .btn-circle {
            margin-left: .5em;
            width: 1.8em;
            height: 1.8em;

            i {
                font-size: .7em;
            }

        }
    }
}

.group-products {
    //table-layout: fixed;
    display: block;
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    //box-shadow: $boxShadow;

    .btn-circle {
        width: 1.8em;
        height: 1.8em;

        i {
            font-size: .7em;
        }

    }

    th, td {
        border: 1px solid #cdcdcd;

        &:first-child {
            border-left: none;
        }

        &:last-child {
            border-right: none;
        }
    }

    th {
        font-weight: 500;
        padding: .2em .5em;
    }

    .products-row-header, .products-row-footer {
        background-color: #dddddd;

        td {
            padding: .2em .5em;
        }
    }

    .products-row-footer td {
        text-align: right;

        &:first-child {
            font-weight: 500;
            text-align: left;
        }
    }

    .products-row {
        td {
            width: auto;
        }

        input {
            border: none;
            width: 100%;
        }

        .cell-reference {
            width: 10%;

            .react-autosuggest__container {
                width: calc(100% - 2em);
                margin-left: 0;
                margin-right: 0;
            }

            .btn-products {
                margin: .2em;
            }
        }

        .cell-long {
            width: 50%;
        }

        .cell-long-product {
            width: 25%;
        }

        .cell-medium {
            width: 15%;
        }

        input[name="quantity"], input[name="surface"], input[name="totalSurface"], input[name="unitPrice"], input[name="totalha"], input[name="marginCoefficient"], input[name="sellingPrice"], input[name="rate"], input[name="hours"], input[name="minutes"], input[name="hourlyRate"], input[name="minuteRate"], input[name="dailyCost"], input[name="daysNumber"], input[name="price"] {
            text-align: right;
        }
    }
}
