.articles-list {
    .articles-free-input {
        width: 100%;
    }

    p {
        margin-bottom: 0!important;
        line-height: 1.4em;
    }
}
