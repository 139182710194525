.form-products {
    .flex-form {
        width: 100%;
        display: flex;
        /*justify-content: space-between;*/
        margin: 1em 1em;
        flex-wrap: wrap;

        input, select {
            margin: 0 1em;
        }

        input:first-child, select:first-child {
            margin-left: 0;
        }

        .designation {
            width: 30em;
        }

        textarea[name="note"] {
            padding-left: .5em;
            padding-right: .5em;
            width: 98%;
        }
    }

    .flex-form:first-child {
        margin-top: 0;
    }
}
