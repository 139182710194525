@import "../variables";

.sidenav {
    //z-index: 1;
    position: fixed;
    left: 0;
    top: 3em;
    width: 15em;
    height: 100%;
    padding: 1em 0;
    background-color: $secondaryColor;
    color: #bdc5cd;
    transition: all 2s;
    overflow-y: auto;

    &.sidenav-hide {
        margin-left: -20%;
    }

    .profile {
        font-size: .95em;
        color: #FFFFFF;
        margin: 3em 2em 1em;
    }

    li {
        cursor: pointer;
        list-style: none;
        padding: 1em 0 1em 2em;
        font-size: .95em;

        &.active {
            background-color: #4b5764;
            border-left: 4px solid $primaryColor;
        }

        a {
            color: #bdc5cd;

            &.active {
                color: #FFFFFF;
            }
        }

        i {
            margin-right: .3em;
        }

        .chevron {
            float: right;
            margin-right: 1.5em;
            font-size: .8em;
        }

        .submenu {
            display: none;
            margin-top: 1em;
            overflow-y: hidden;

            &.active {
                display: block;
            }

            li {
                padding-top: .6em;
                padding-bottom: .6em;
            }
        }
    }

    @media print {
        display: none;
    }
}

@media screen and (max-width: 1250px) {
    .sidenav.sidenav-hide {
        margin-left: -32%;
    }
}

@media screen and (max-width: 770px) {
    .sidenav {
        display: block;
        z-index: 9;
        margin-left: 0;
        transition: all 1s ease-in-out;

        &.sidenav-hide {
            display: none;
            margin-left: 0;
        }
    }
}

@media screen and (min-width: 771px) {
    .sidenav {
        display: none;
    }
}
