.bills {
    @media print {
        @page {
            size: auto;
        }
        /*transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -moz-transform:rotate(-90deg);*/
    }
}
