@import "../../variables";

.orders-print {
    display: none;
    padding: 1em;

    @media print {
        display: block;

        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        font-size: 18px;
    }

    a, a:active, a:hover {
        color: #000;
        text-decoration: none;
    }

    .header {
        display: flex;
        justify-content: space-between;
        padding: 1em;

        h2 {
            font-size: 1.4em;
        }

        .left-header, .right-header {
            height: auto;
            padding: 1em 8em;
            border: 1px solid #000000;
            border-radius: 4px;
        }

        .left-header {
            display: flex;
            align-items: center;

            .infos {
                margin-left: 3em;
            }
        }
    }

    .articles-list {
        margin-top: 1em;
        width: 100%;
        font-size: 18px;

        th, td {
            padding: .2em .5em;
            border: 1px solid #424242;
            vertical-align: top;
        }

        th {
            background-color: $primaryColor;
            text-align: center;
        }

        .medium-cell {
            width: 7em;
        }

        .small-cell {
            width: 4.75em;
        }

        .quantity-cell {
            width: 5px!important;
        }
    }
}
