@import "../../variables";

.form-customers {
    .addContact {
        display: inline-block;
        margin-top: 3em;
        padding: .2em 2em;
        background-color: $primaryColor;
        box-shadow: $boxShadow;
        cursor: pointer;

        &:hover {
            background-color: #e19e4e;
            color: #FFFFFF;
        }
    }

    .deleteContact {
        margin-top: 1.5em;
    }

    .submitButton {
        display: block;
        margin-top: 2em;
    }

    .form-block-title {
        font-size: 1.1em;
    }

    label {
        display: block;
        font-weight: 500;
    }

    input {
        width: 100%;
    }

    input, textarea {
        /*border: 1px solid #e6e6e6;
        box-shadow: $boxShadow;*/
    }

    .customer-note {
        margin-top: 3em;

        h3 {
            font-size: 1.1em;
        }

        textarea {
            padding: .2em .5em;
            width: 100%;
            min-height: 2.5em;
        }
    }

    .row-contact {
        margin-top: 1em;
    }
}
