/*@page {
    size: A4;
    margin: 0;
}
@media print {
    html, body {
        width: 210mm;
        height: 297mm;
    }
}*/

.print {
    visibility: hidden;
    position: absolute;
    top: -400%;
    left: -400%;

    @media print {
        position: relative;
        visibility: visible;
        top: 0;
        left: 0;
    }

    .text-center {
        text-align: center;
    }

    .bold {
        font-weight: bold;
    }

    .uppercase {
        text-transform: uppercase;
    }

    p {
        margin: 0!important;
    }

    .container-fluid, .container {
        padding: 0;
    }

    .container {
        padding: 2em 1.5em;
        width: 100%;
    }

    .panel-header {
        margin-top: 50px;
    }

    .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
        padding: 2px 6px;
    }

    .table-bordered th:first-child, .table-bordered th:last-child, .table-bordered td:first-child, .table-bordered td:last-child {
        border: none!important;
    }

    .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th/*, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th*/ {
        border-top: 0!important;
        border-bottom: 0!important;
    }

    .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th/*, .table>thead>tr>td, .table>thead>tr>th*/ {
        border-top: 0!important;
    }

    .panel {
        margin-bottom: 20px;
        border: 1px solid black;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0,0,0,.05);
    }

    .panel-header {
        margin-top: 50px;
    }

    .header {
        .left-header {
            font-size: 1em;

            .logo-me {
                width: 23em;
            }

            p {
                margin-top: 10px!important;
            }
        }

        h5 {
            font-size: 1.1em;
        }

        p {
            text-align: center;
        }
    }

    .customer {
        margin-top: 3.5em;
        padding-left: 8em;

        p {
            text-align: left;
            font-size: 1em;
        }
    }

    .articles {
        font-size: 1em;
        border-collapse: separate;
        border-spacing: 0;
        margin-bottom: 20px;
        width: 100%;

        tr {
        }

        th, td {
            vertical-align: baseline;
            padding: 2px 6px;
            border: .5px solid #dddddd;
            border-top: 0;
            border-bottom: 0;
        }

        td {
            line-height: 1em;
        }

        tr:first-child th {
            border-top: 1px solid #000000;
            border-bottom: 1px solid #dddddd;
        }

        tr:last-child td {
            border-bottom: 1px solid #000000;
        }

        th:first-child, td:first-child {
            border-left-color: #000;
        }

        th:last-child, td:last-child {
            border-right-color: #000;
        }

        tr:first-child th:first-child { border-top-left-radius: 3px; }
        tr:first-child th:last-child { border-top-right-radius: 3px; }
        tr:last-child td:first-child { border-bottom-left-radius: 3px; }
        tr:last-child td:last-child { border-bottom-right-radius: 3px; }

        thead {
            th:first-child {
                width: 71%;
            }

            th:nth-child(2), th:nth-child(3) {
                width: 8%;
            }

            th:last-child {
                width: 13%;
            }
        }

        .text-article {
            padding-left: 2em;
            font-size: 1.2em;
            line-height: 1.2em;
        }

        .amounts-article {
            text-align: right;
            vertical-align: bottom;
        }
    }

    .deliveryDelay {
        font-size: 1em;
        line-height: 25px;
        margin: 0;
        padding-left: 10px;
    }

    .signature {
        height: 76px;
    }


    .amounts {
        font-size: 1em;
        border-collapse: separate;
        border-spacing: 0;
        margin-bottom: 20px;
        width: 100%;

        th, td {
            padding: 2px 6px;
            border: .5px solid #dddddd;
            /*border-top: 0;
            border-bottom: 0;*/
        }

        tr:first-child th {
            border-top-color: #000;
        }

        tr:last-child td {
            border-bottom-color: #000;
        }

        th:first-child, td:first-child {
            border-left-color: #000;
        }

        th:last-child, td:last-child {
            border-right-color: #000;
        }

        tr:first-child th:first-child { border-top-left-radius: 3px; }
        tr:first-child th:last-child { border-top-right-radius: 3px; }
        tr:last-child td:first-child { border-bottom-left-radius: 3px; }
        tr:last-child td:last-child { border-bottom-right-radius: 3px; }

        thead tr th {
            font-size: 1em;
            text-align: center;
        }

        tbody tr td {
            text-align: center;
            font-weight: bold;
        }
    }

    .footer {
        text-align: center;
        font-size: .85em;
    }

    .cgv {
        page-break-before: always;

        h2 {
            font-size: 1.5em;
        }

        h3 {
            font-size: 1.2em;
            margin-top: 1em;
        }

        h4 {
            font-size: 1em;
        }

        p {
            font-size: .7em;
            text-align: justify;
        }

        .cgv-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 24px;

            .cgv-col {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }
}

@media print {
    .print {
        visibility: visible;
    }

    .table thead th {
        border: none!important;
        border-bottom: 1px solid #000000!important;
    }

    .table-bordered>tbody>tr>td, .table-bordered>tbody>tr>th, .table-bordered>tfoot>tr>td, .table-bordered>tfoot>tr>th/*, .table-bordered>thead>tr>td, .table-bordered>thead>tr>th*/ {
        border-top: 0!important;
        border-bottom: 0!important;
    }

    .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th/*, .table>thead>tr>td, .table>thead>tr>th*/ {
        border-top: 0!important;
    }
}
