.permissions {
    table tbody tr:nth-child(2n) {
        background-color: #f0f0f0;
    }

    table {
        input[type="checkbox"] {
            cursor: pointer;
        }

        .btn-circle {
            width: 1.7em;
            height: 1.7em;

            i {
                font-size: .65em;
            }
        }
    }
}
