@import "~antd/lib/message/style/index.css";
@import "variables";
@import "page";
@import "form";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
    text-decoration: none;
    color: $primaryColor;

    &:hover {
        text-decoration: none;
        color: $primaryColor;
    }
}

ul {
    padding: 0;
}

button i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


.btn-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
        margin: 0 auto;
    }
}

.btn-circle {
    cursor: pointer;
    position: relative;
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto;

    i {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
        font-size: .8em;
    }

    &.btn-secondary, &.btn-warning, &.btn-primary, &.btn-danger, &.btn-success, &.btn-info {
        border: none;
    }

    &.btn-color {
        background-color: #FFFFFF;
        border: 2px solid #dddddd;
    }
}

.text-right {
    text-align: right;
}

.ck.ck-dropdown .ck-dropdown__arrow {
    z-index: 0!important;
}

.sr-btn {
    padding: .2em 2em;
    border: 1px solid #f0f0f0;
    background-color: $primaryColor;
    display: inline-block;
    cursor: pointer;

    &:hover {
        background-color: #e19e4e;
        color: #FFFFFF;
    }
}

.flex {
    display: flex;
}

.form-control {
    border-radius: 0;
}

.form-control.is-valid, .was-validated .form-control:valid, .form-control.is-invalid, .was-validated .form-control:invalid {
    padding-right: 0;
    background: none;
}

.sr-loading {
    margin: 0 auto;
}

.alert-connected {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    color: red;
    background-color: white;
    padding: 2em;
}
