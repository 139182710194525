@import "variables";

.page {
    margin-top: 4em;
    margin-left: 15em;
    padding: 1em;
    transition: all 1.5s;

    &.sidenav-hide {
        margin-left: 0;
    }

    .box {
        background-color: #FFFFFF;
        padding: 1em;
        border-radius: 8px;
        box-shadow: $boxShadow;
        width: 100%;

        .box-header {
            display: flex;
            justify-content: space-between;

            .title {
                margin-bottom: 1em;
                font-size: 1.5em;
                font-weight: 500;
            }

            .btn-header-right {
                a, div {
                    margin-left: .5em;
                }
            }

            .add-resource {
                margin: 0;
                background-color: $primaryColor;
                color: #fff;
                border: none;
            }
        }
    }

    @media screen and (max-width: 770px) {
        display: none;
        margin-left: 0;
        padding: 1em .5em;
        transition: all 1s ease-in-out;

        &.sidenav-hide {
            display: block;
            margin-left: 0;
        }
    }

    @media print {
        @page {
            size: auto;
        }
        margin: 0;
        padding: 0;

        .box {
            margin: 0;
            padding: 0;
        }
    }
}
