@import "../../variables";

@import "./measures.scss";
@import "./products.scss";

.accounting {
    select[name="models"] {
        max-width: 100%;
    }

    .form-accounting {
        label {
            display: block;
        }

        .saveAsModel {
            display: inline;
        }

        input[name="model"] {
            margin-top: .4em;
            margin-left: 1em;
        }
    }

    .saved-buttons button {
        @media screen and (max-width: 550px){
            width: 100%;
            margin-bottom: 1em;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .accounting-note {
        .note-title {
            font-size: 1.1em;
        }
    }
}
