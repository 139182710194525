@import "../variables";

body {
    background-color: #f0f0f0;
}

.login {
    width: 100%;

    form {
        position: relative;
        width: 25%;
        margin: 10vh auto 0;
        padding: 1em;
        background-color: #FFFFFF;
        border: 1px solid #ced4da;
        border-radius: $borderRadius;
        box-shadow: $boxShadow;
        text-align: center;

        h1 {
            margin: 0 0 1em 0;
            font-size: 2em;
            font-weight: 400;
        }

        input {
            display: block;
            width: 100%;
            margin: .7em 0;
            padding: .5em 1em;
            border: 1px solid #ced4da;
            border-radius: .25em;
        }

        button {
            margin-top: 1em;
            width: 100%;
            padding: .4em 1em;
            border: none;
            border-radius: 8px;
            color: #FFFFFF;
            background-color: $primaryColor;
        }

        button:hover {
            color: #ffffff;
            background-color: $primaryColorHover;
        }
    }
}

@media screen and (max-width: 1200px) {
   .login form {
        width: 35%;
    }
}

@media screen and (max-width: 1000px) {
    .login form {
        width: 50%;
    }
}

@media screen and (max-width: 550px) {
    .login form {
        width: 90%;
    }
}






